
import axios from 'axios';
const token=  localStorage.getItem('staron_token');
const instance = axios.create({
  baseURL: 'https://apisystem.agricapital-eg.com/api/v1', 
  headers: {
    'Content-Type': 'application/json',
    "Authorization": `Bearer ${token}`,
   
  }, withCredentials: true,
  // Replace with your Laravel backend URL
});
export default async function LoginApi(credentials){
    try {
        const response = await instance.post('/login', credentials);
        // console.log(response.data);
        return response.data;
      }  catch (error) {
        if (error.response && error.response.data && error.response.data.message) {
          throw new Error(error.response.data.message);
        } else {
          throw new Error('Login failed'); // Fallback error message if the response structure is unexpected
        }
      }
}
export const logout = async () => {
  try {
    const response = await instance.post('/logout',

    );
    return response.data;
  } catch (error) {
    throw new Error('Logout failed');
  }
};