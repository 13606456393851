import { BrowserRouter, Route, Router, Routes } from 'react-router-dom';
import"./css/tabler.min.css" ;
// import"./css/tabler-flags.min.css" ;
// import"./css/tabler-payments.min.css" ;
import"./css/tabler-vendors.min.css" ;
import"./css/demo.min.css" ;
import './App.css';
import Login from './Layout/Login';
import Dashboard from './Layout/Dashboard';
import PublicComponent from './Pages/Public/Index';
import Content from './Pages/Public/Content';
import EmployeeAttendance from './Pages/Public/EmploeeAttendance';
import Request from './Pages/Public/Request';

function App() {
  return (
    <BrowserRouter>

    <Routes>
      <Route path="/login" element={<Login/>} />
      <Route path="/" element={<Dashboard/>} >
        <Route index element={<Content/>}/>
        <Route path="public" element={<PublicComponent/>} >
        <Route path="attendance" element={<EmployeeAttendance/>}/>
        <Route path="requests" element={<Request/>}/>
        <Route path="requirement" element={<Content/>}/>

        {/* <Route path="attendance" element={<EmployeeAttendance/>} /> */}


        </Route>
       {/*  <Route path="/RFE" element={<RFE/>} />
        <Route path="/RFEDashbord" element={<RFEDashbord/>} /> */}
        {/* <Route path="employee" element={<Empolyee/>} >
             <Route index element={<AllEmployee/>} />
             <Route path="create" element={<CreatEmployee/>} />
             <Route exact path=":id" element={<ShowEmployee/>} />
             <Route path=":id/edit" element={<UpdateEmployee/>} />

        </Route> */}
        {/* <Route path="attendance" element={<Attendance/>} >
             <Route index element={<AllAttendance/>} />
             <Route path="create" element={<CreatAttendance/>} />
             <Route path="create/exel" element={<CreatExelAttendance/>} />
             <Route path=":id/edit" element={<UpdateAttendance/>} />
             <Route path=":id/Addetion" element={<Addetion/>} />
             <Route path=":id/Deduction" element={<Deduction/>} />
             <Route path=":id/Deductiondata" element={<Alldeduction/>} />

        </Route> */}
        {/* <Route path="/Request-Approvable" element={<RequestApprovable/>} />
        <Route path="/global-holyday" element={<GlobalHolyday/>} />
        <Route path="/payroll" element={<Payroll/>} />
        <Route path="/leave-balance" element={<LeaveBalance/>} />
        <Route path="/warning" element={<Warning/>} />
         */}

      </Route>
      
    </Routes>

    </BrowserRouter>
  );
}

export default App;
