// src/Login.js

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import LoginApi from '../Apis/Auth';
// import { login } from '../Api';

const Login = () => {
  const navigate = useNavigate();
  const [loginerror,Seterror]=useState("");
  const [emailerror,setemailerror]=useState("");
  const [passworderror,setpassorderror]=useState("");
  const [credentials, setCredentials] = useState({
    email: '',
    password: '',
  });

  const handleChange = (e) => {
    setCredentials({ ...credentials, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await LoginApi(credentials);
        if (response.status==400) {
            Seterror("");
            if(response.message.password){
                setpassorderror(response.message.password);
            }
            
            if(response.message.email){
                setemailerror(response.message.email);
            }
        }
        else if(response.status==200){
            localStorage.setItem('staron_token', response.token);
            const jsonString = JSON.stringify(response.user);
            localStorage.setItem('staron_user', jsonString);
            console.log(response.user);
            navigate('/');
        }
        else{
            Seterror(response.message);
            setpassorderror("");
            setemailerror("");
        }

    } catch (error) {
        console.log(error.message); // Assuming the error response contains a 'data' field with the error message
    }
  };

  return (
    <div  className="border-top-wide border-primary d-flex flex-column theme-dark">
    <div className="page page-center">
      <div className="container-tight py-4">
        <div className="text-center mb-4">
          <a href="." className="navbar-brand navbar-brand-autodark"><img src={require("../assets/Untitled-2-02.png")} height="36" alt=""/></a>
        </div>

        <form className="card card-md" onSubmit={handleSubmit} autoComplete="on">
        {loginerror!=""? <p className="card-text">
                      {loginerror}</p>:<></>}
          <div className="card-body">
          
            <h2 className="card-title text-center mb-4">Login to your account</h2>
            <div className="mb-3">
              <label className="form-label">Email address</label>
              <input type="email"
               className="form-control"
                    // value={credentials.email}
                    onChange={handleChange}
                    required                     name="email"

                     placeholder="Enter email"/>
                      
            </div>
            {
                        emailerror!=""?<label id="cemail-error" 
                        className="error mt-2 text-danger" htmlFor="cemail">
                           {emailerror}</label>:<></>
                    }
            <div className="mb-2">
              <label className="form-label">
                Password
                <span className="form-label-description">
                  {/* <a href="./forgot-password.html">I forgot password</a> */}
                </span>
              </label>
              <div className="input-group input-group-flat">
                <input type="password" className="form-control" 
                //  value={credentials.password}
                 onChange={handleChange}
                 required name="password"
                placeholder="Password"  
                autoComplete="on"/>
                {/* <span className="input-group-text">
                  <a href="#" className="link-secondary" title="Show password" data-bs-toggle="tooltip">
                    <svg xmlns="http://www.w3.org/2000/svg" className="icon" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><circle cx="12" cy="12" r="2" /><path d="M22 12c-2.667 4.667 -6 7 -10 7s-7.333 -2.333 -10 -7c2.667 -4.667 6 -7 10 -7s7.333 2.333 10 7" /></svg>
                  </a>
                </span> */}
                
              </div>
              {
            passworderror!=""?<label id="cpassword-error"
             className="error mt-2 text-danger"
             htmlFor="cpassword"
             style={{display:"block",width:"100%"}}
             >
                {passworderror}
                </label>:<></>

        }
            </div>
           
            <div className="form-footer">
              <button type="submit" className="btn btn-primary w-100">Sign in</button>
            </div>
          </div>

        </form>
       
      </div>
    </div>

  </div>
   
      
  );
};

export default Login;